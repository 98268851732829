.statistics-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .statistics-header {
    display: flex;
    align-items: center;
    gap: 12px;
  
    .statistic-name {
      margin: 0;
      font-size: 13px;
      color: gray;
    }
  
    .bg-custom-light {
      color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 20px;
      }
      &.custom-danger {
        background-color: rgba(220, 53, 69, 0.2) !important;
      }
      &.custom-success {
        background-color: rgba(81, 187, 37, 0.2) !important;
      }
    }
  }
}

.color-gray {
  color: gray;
}

.text-bold {
  font-weight: bold;
}

.font-sm {
  font-size: 13px;
}